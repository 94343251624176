import React, { useState, useRef, useCallback } from 'react';
import { RichText } from '../../richTextOptions';
import Modal from 'react-bootstrap/Modal';
import { useOutsideHandling } from '../../Hooks/CustomHooks';

const svgQuestion = `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="question-circle" class="svg-inline--fa fa-question-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"></path></svg>`;

const ToolTipHover = ({ content }) => {
    return (
        <span tabIndex={0} className="tooltip-wrapper">
            <span className="wrap">
                <span className="icon" dangerouslySetInnerHTML={{ __html: svgQuestion }}></span>
                <span className="tooltip-custom">
                    <span className="icon" dangerouslySetInnerHTML={{ __html: svgQuestion }}></span>
                    <span className="content-tooltip">{content}</span>
                </span>
            </span>
        </span>
    )
}

const ToolTipClick = ({ content, labelClick, position }) => {
    const [openTooltip, setOpenTooltip] = useState(false)
    const [openTooltipPopup, setOpenTooltipPopup] = useState(false)

    const handleClickOutside = useCallback(() => {
        setOpenTooltip(false);
    }, [])

    const handleClickTooltip = () => {
        setTimeout(() => {
            if (!openTooltip) {
                setOpenTooltip(true)
            }
        }, 100);
    }

    const wrapperRef = useRef(null)

    useOutsideHandling(wrapperRef, handleClickOutside);

    return (
        <>
            <button className=" is-desktop tooltip-button" onClick={() => handleClickTooltip()}>
                {labelClick}
                <span className="tooltip-click">
                    <span className="icon" dangerouslySetInnerHTML={{ __html: svgQuestion }}></span>
                    {
                        openTooltip && (
                            <div className={`content-wrapper-tooltip ${position}`} ref={wrapperRef}>
                                <span className="content"><RichText data={content} /></span>
                            </div>
                        )
                    }
                </span>
            </button>
            <button className=" is-mobile tooltip-button" onClick={() => setOpenTooltipPopup(true)}>
                {labelClick}
                <span className="tooltip-click">
                    <span className="icon" dangerouslySetInnerHTML={{ __html: svgQuestion }}></span>
                    {
                        openTooltip && (
                            <div className={`content-wrapper-tooltip ${position}`} ref={wrapperRef}>
                                <span className="content"><RichText data={content} /></span>
                            </div>
                        )
                    }
                </span>
            </button>
            <Modal className="popup-tooltip" show={openTooltipPopup} onHide={() => setOpenTooltipPopup(false)}>
                <Modal.Body>
                    <div className="popup-tooltip-wrapper">
                        <RichText data={content} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

const Tooltip = ({ content, action, labelClick, position }) => {
    let component = "";
    switch (action) {
        case "click":
            component = <ToolTipClick content={content} labelClick={labelClick} position={position} />
            break;
        default:
            component = <ToolTipHover content={content} />
            break;
    }
    return (
        <>
            {component}
        </>
    )
}

export { Tooltip };
