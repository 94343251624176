import React, { Component } from 'react';
import Block from '../block';
import Slider from 'react-slick';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { RichText } from '../richTextOptions';
import { getIsPreviewStatus } from '../../state/app';
import { Tooltip } from './Common/Tooltip';

const TopHealthIssueSlider = ({ petType, items, settings, className, isPreview }) => {
     return (
          <div className={className}>
               <Slider {...settings}>
                    {items && (
                         items.map((item, i) => {
                              let riskLevel = '';

                              if (item.type === "ContentfulBlockTopHealthIssue") {
                                   riskLevel = item.riskLevel;
                              } else {
                                   switch (petType) {
                                        case 'Puppies':
                                             riskLevel = item.riskLevelForPuppies;
                                             break;
                                        case 'Adult Dogs':
                                             riskLevel = item.riskLevelForAdultDogs;
                                             break;
                                        case 'Older Dogs':
                                             riskLevel = item.riskLevelForOlderDogs;
                                             break;
                                        case 'Kittens':
                                             riskLevel = item.riskLevelForKittens;
                                             break;
                                        case 'Adult Cats':
                                             riskLevel = item.riskLevelForAdultCats;
                                             break;
                                        case 'Older Cats':
                                             riskLevel = item.riskLevelForOlderCats;
                                             break;
                                   }
                              }

                              let conditionType = item.conditionType
                              let nameCondition = item.nameCondition
                              let classify = item.classify ? item.classify : '';
                              let tooltip = item.tooltip ? item.tooltip : '';
                              let textRiskLevelFor = item.textRiskLevelFor
                              let vetBillAmount = item.vetBillAmount
                              let textRiskLevelForDefault = '';
                              if (isPreview) {
                                   conditionType = item.fields.conditionType
                                   nameCondition = item.fields.nameCondition
                                   classify = item.fields.classify ? item.fields.classify : '';
                                   tooltip = item.fields.tooltip ? item.fields.tooltip : '';
                                   textRiskLevelFor = item.fields.textRiskLevelFor
                                   vetBillAmount = item.fields.vetBillAmount

                                   riskLevel = item.fields.riskLevel;
                                   if (typeof riskLevel === "undefined") {
                                        switch (petType) {
                                             case 'Puppies':
                                                  riskLevel = item.fields.riskLevelForPuppies;
                                                  break;
                                             case 'Adult Dogs':
                                                  riskLevel = item.fields.riskLevelForAdultDogs;
                                                  break;
                                             case 'Older Dogs':
                                                  riskLevel = item.fields.riskLevelForOlderDogs;
                                                  break;
                                             case 'Kittens':
                                                  riskLevel = item.fields.riskLevelForKittens;
                                                  break;
                                             case 'Adult Cats':
                                                  riskLevel = item.fields.riskLevelForAdultCats;
                                                  break;
                                             case 'Older Cats':
                                                  riskLevel = item.fields.riskLevelForOlderCats;
                                                  break;
                                        }
                                   }
                              }

                              riskLevel = riskLevel?.toLowerCase();

                              switch (conditionType.linkText || conditionType.fields.linkText) {
                                   case 'Wellness':
                                        textRiskLevelForDefault = 'Level of need for'
                                        break;
                                   default:
                                        textRiskLevelForDefault = 'Risk level for'
                                        break;
                              }

                              return (
                                   <div className="wrap" key={i}>
                                        <div className="issue-item">
                                             <div className="issue-type">
                                                  <Block block={conditionType} />
                                             </div>
                                             <div className="issue-name">
                                                  <div className="name"><span>{nameCondition}</span>{tooltip && <><Tooltip content={tooltip} /></>}</div>
                                                  {classify && <p className="classify">{classify}</p>}
                                             </div>
                                             <div className="typical-vet-bill">
                                                  <label>Typical vet bill:</label>
                                                  <span className="cost">${vetBillAmount.toLocaleString('en-US')}</span>
                                             </div>
                                             <div className="risk-level">
                                                  <span className={`risk-level-point ${riskLevel}`}></span>
                                                  <img loading="lazy" src="/images/risk-level.svg" alt="risk level" width="189" height="9"/>
                                                  <label>{textRiskLevelFor || textRiskLevelForDefault} <span className="growth-period">{petType}</span></label>
                                             </div>
                                        </div>
                                   </div>
                              )
                         })
                    )}
               </Slider>
          </div>
     )
}

class BlockListTopHealthIssues extends Component {
     constructor(props) {
          super(props);
     }
     
     render() {
          const { headline, petType, listIssues, bottomText, ctaButton, textLinkAllPageProducts, linkAllPageProducts, isPreview } = this.props;
          const settings = {
               autoplay: false,
               autoplaySpeed: 5000,
               speed: 600,
               dots: false,
               arrows: false,
               infinite: true,
               slidesToShow: 4,
               slidesToScroll: 1,
               variableWidth: true,
               beforeChange: () => {
                    $('.list-issues .slick-slide a').attr('tabindex', -1)
               },
               afterChange: () => {
                    $('.list-issues .slick-slide.slick-current a').attr('tabindex', 0)
               },
               responsive: [
                    {
                         breakpoint: 1199,
                         settings: {
                              dots: true,
                              slidesToShow: 3,
                              variableWidth: false,
                         }
                    },
                    {
                         breakpoint: 991,
                         settings: {
                              dots: true,
                              slidesToShow: 2,
                              variableWidth: false,
                         }
                    },
                    {
                         breakpoint: 767,
                         settings: {
                              dots: true,
                              slidesToShow: 1.25,
                         }
                    },
                    {
                         breakpoint: 374,
                         settings: {
                              dots: true,
                              slidesToShow: 1,
                              // adaptiveHeight: true,
                         }
                    },
               ],
          };

          return (
               <section className='top-health-issues'>
                    <div className="container">
                         {headline && (
                              <div className="headline">
                                   <RichText data={headline} />
                              </div>
                         )}
                         <TopHealthIssueSlider petType={petType} items={listIssues} settings={settings} className={'list-issues'} isPreview={isPreview} />
                         {bottomText && (
                              <div className="bottom-text">
                                   <RichText data={bottomText} />
                              </div>
                         )}
                         {ctaButton && (
                              <div className="cta-button">
                                   <Block block={ctaButton} />
                              </div>
                         )}
                         {textLinkAllPageProducts && (
                              <div className='link-all-products-page'>
                                   <a href={linkAllPageProducts}>{textLinkAllPageProducts}</a>
                              </div>
                         )}
                    </div>
               </section>
          )
     }
}

function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state)
     };
}

BlockListTopHealthIssues.propTypes = {
     isPreview: PropTypes.bool,
}
const ContentfulBlockListTopHealthIssues = connect(mapStateToProps)(BlockListTopHealthIssues);

export default ContentfulBlockListTopHealthIssues;
